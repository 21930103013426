@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(to right, #ebf8ff, #ffebef);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.linegrow {
  position: relative;
  color: #7d45ed;
  text-decoration: bold;
  font-size: 13px;
}
.linegrow:hover {
  font-weight:600;
  color: #ba54d9;
}
.linegrow::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #de2381;
  transition: width 0.3s ease; /* Transition width property */
}

.linegrow:hover::before {
  width: 100%; /* Grow the line to full width on hover */
}

.overlay-text {
  height: 50px;
  width: 100%; /* Adjust width to 100% for full width */
  position: relative;
  bottom: 70px; /* Position at the bottom */
  left: 50%; /* Move halfway across the parent */
  transform: translateX(-50%); /* Center horizontally */
  color: white;
  font-size: 16px; /* Adjust font size for mobile */
  background-color: #531b7a; /* Use your desired shade of gray */
  padding-left: 20px; /* Adjust padding for mobile */
  margin-bottom: 20px; /* Adjust margin bottom for mobile */
}

@media (min-width: 768px) {
  .overlay-text {
    width: 250px; /* Adjust width for larger screens */
    font-size: 24px; /* Adjust font size for larger screens */
    padding-left: 20px; /* Adjust padding for larger screens */
    margin-bottom: 50px; /* Adjust margin bottom for larger screens */
  }
}

.half-circle {
  background: linear-gradient(to right, #0c183b 50%, #ffffff 50%);
  border: 3px solid #f5f8fc;
}

.text-gradient {
  background: linear-gradient(to right, rgba(40, 5, 97, 1.00), rgba(164, 64, 194, 1.00), rgba(220, 87, 169, 0.82));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

/* Courtesy.css */
.slick-dots li button:before {
  color: #6232a8; /* Custom color for inactive dots */
}

.slick-dots li.slick-active button:before {
  color: #fc1930; /* Custom color for active dots */
}

.slick-prev, .slick-next{
  background-color: #8037ed !important;
  opacity: 0.8;
  height: 19px;
  padding-top: 1px;
  border-radius: 10px;
}

.circle-container {
  position: relative;
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle-content {
  position: relative;
  z-index: 1;
}

.rotating-dashes {
  position: absolute;
  top: 0;
  left: 0;
  animation: spin 50s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.greyscale {
  filter: grayscale(100%);
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.animate-fadeOut {
  animation: fadeOut 3s forwards;
  transition: opacity 3s ease-in-out;
}

/* styles.css */
.player-highlight {
  background-color: #fefcbf; /* Light yellow for better contrast */
}

/* Mobile-specific styles */
@media (max-width: 640px) {
  .player-highlight {
    background-color: #fefcbf; /* Ensure visibility on mobile */
  }
}